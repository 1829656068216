import { keccak256 } from '@ethersproject/keccak256'
import { CurrencyAmount, Price } from '@uniswap/sdk-core'
import { useActiveChainId } from 'connection/useActiveChainId'
import { TRADE_ROUTER_ADDRESSES } from 'constants/addresses'
import { BigNumber } from 'ethers/lib'
import { solidityPack } from 'ethers/lib/utils'
import { useCurrency } from 'hooks/Tokens'
import useAutoSlippageTolerance from 'hooks/useAutoSlippageTolerance'
import { useOrderBookContract, useRoxUtils, useTradeReader, useV3PoolContract } from 'hooks/useContract'
import { useSingleCallResult } from 'lib/hooks/multicall'
// import { useSingleCallResult } from 'lib/hooks/multicall'
import { useEffect, useMemo } from 'react'
import { useTradeSWAPState } from 'state/positionSwap/hooks'
import { useUserSlippageTolerance } from 'state/user/hooks'
import { SlippageTolerance } from 'state/user/types'
import { compareAddress } from 'utils'
import { BN, fromSqrt96Wei, toWei } from 'utils/bn'
import { zeroAddress } from 'viem'

import { feeProps, OrederListProps, OrederType, TradePositionProps } from './types'

export const useGetPositions = (): TradePositionProps[] | undefined => {
  const { account, chainId } = useActiveChainId()
  const tradeReader = useTradeReader()
  const tradeRouter = chainId ? TRADE_ROUTER_ADDRESSES[chainId] : undefined

  const { result: Positions } = useSingleCallResult(tradeReader, 'getPositions', [account, tradeRouter], {
    gasRequired: 6000000,
  })
  return useMemo(
    () => Positions?.[0].filter((x: TradePositionProps) => !compareAddress(x.account, zeroAddress)),
    [Positions]
  )
}

export const useOrderList = () => {
  const { account } = useActiveChainId()
  const orderContract = useOrderBookContract()

  const { result } = useSingleCallResult(orderContract, 'getPendingOrders', [account])
  return useMemo(() => {
    if (!result) return

    const Limit = result[0].map((x: OrederListProps) => ({ ...x, type: OrederType.Limit }))
    const trigger = result[1].map((x: OrederListProps) => ({ ...x, type: OrederType.trigger }))
    return Limit.concat(trigger) as OrederListProps[]
  }, [result])
}

export const useEstimateIncrease = (
  long0?: boolean,
  _rgPool?: string,
  colAmount?: string,
  currentSqrtRatioX96?: BigNumber,
  sizeIs0?: boolean,
  decimals0?: number,
  decimals1?: number
) => {
  const tradeReader = useTradeReader()

  const { account } = useActiveChainId()

  const { sliederLever, isToken0 } = useTradeSWAPState()

  const parmes = useMemo(() => {
    if (!_rgPool || !colAmount || !account || !currentSqrtRatioX96) return [undefined]
    let lls
    let longs
    if (isToken0) {
      if (long0) {
        lls = BN(colAmount).times(fromSqrt96Wei(currentSqrtRatioX96.toString()))
      } else {
        lls = BN(colAmount).times(BN(1).div(fromSqrt96Wei(currentSqrtRatioX96.toString())))
      }
      longs = long0 ? 1 : 0
    } else {
      if (long0) {
        lls = BN(colAmount).times(BN(1).div(fromSqrt96Wei(currentSqrtRatioX96.toString())))
      } else {
        lls = BN(colAmount).times(fromSqrt96Wei(currentSqrtRatioX96.toString()))
      }
      longs = long0 ? 0 : 1
    }

    let sizeDelta = sliederLever > 0 ? BN(sliederLever).times(lls).toFixed(0) : 0
    if (sizeIs0) {
      sizeDelta = '0'
      longs = long0 ? 1 : 0
    }
    return [_rgPool, account, colAmount, sizeDelta, longs]
  }, [_rgPool, account, colAmount, currentSqrtRatioX96, isToken0, long0, sizeIs0, sliederLever])

  // const { data: s, error } = useContractRead({
  //   address: tradeReader?.address as any,
  //   abi: tradeReaderABI.abi,
  //   functionName: 'estimateIncrease',
  //   args: parmes as any,
  // })

  // console.log('[s]:', s)
  const { result, loading, error } = useSingleCallResult(tradeReader, 'estimateIncrease', parmes as any)
  useEffect(() => {
    // console.log('estimateIncrease', result, parmes, error)
  }, [error, parmes, result])
  const data = useMemo(() => {
    if (!result) return
    let sqrt96 = fromSqrt96Wei(result.position.entrySqrtPriceX96.toString() || 0, decimals0, decimals1)
    let prevOpenPrice = fromSqrt96Wei(result.prevOpenPrice.toString() || 0, decimals0, decimals1)
    let prevLiqPrice = fromSqrt96Wei(result.prevLiqPrice.toString() || 0, decimals0, decimals1)
    let liqPrice = fromSqrt96Wei(result.liqPrice.toString() || 0, decimals0, decimals1)
    const spread = BN(result.position.openSpread.toString()).div(10000)

    if (sqrt96.isNaN()) {
      sqrt96 = BN(0)
    }
    if (prevOpenPrice.isNaN() || prevOpenPrice.toString() == '0') {
      prevOpenPrice = BN(0)
    }
    if (prevLiqPrice.isNaN() || prevLiqPrice.toString() == '0') {
      prevLiqPrice = BN(0)
    }
    if (liqPrice.isNaN()) {
      liqPrice = BN(0)
    }

    if (!isToken0) {
      if (!sqrt96.eq(0)) sqrt96 = BN(1).div(sqrt96)
      if (!liqPrice.eq(0)) liqPrice = BN(1).div(liqPrice)
      if (!prevOpenPrice.eq(0)) prevOpenPrice = BN(1).div(prevOpenPrice)
      if (!prevLiqPrice.eq(0)) prevLiqPrice = BN(1).div(prevLiqPrice)
    }

    return {
      prevOpenPrice: prevOpenPrice.toFixed(),
      prevLiqPrice: prevLiqPrice.toFixed(),
      sqrt96: sqrt96.toFixed(),
      liqPrice: liqPrice.toFixed(),
      spread: spread.toFixed(),
    }
  }, [decimals0, decimals1, isToken0, result])

  return {
    result: data,
    loading,
  }
}

export const useEstimateDecrease = (
  long0?: boolean,
  _rgPool?: string,
  _sizeDelta?: string,
  _collateralDelta?: string
) => {
  const tradeReader = useTradeReader()

  const { account } = useActiveChainId()

  const parmes = useMemo(() => {
    if (!_rgPool || !_sizeDelta || !account || !_collateralDelta || long0 === undefined) return
    const key = gethash(account, _rgPool, long0)

    return [_rgPool, _sizeDelta, _collateralDelta, key]
  }, [_collateralDelta, _rgPool, _sizeDelta, account, long0])

  const { result, loading, error } = useSingleCallResult(tradeReader, 'estimateDecrease', parmes ?? [undefined])
  useEffect(() => {
    console.log('estimateDecrease', parmes,result, loading, error)
  }, [error, loading, parmes, result])
  const data = useMemo(() => {
    if (!result) return
    return { ...result?.[0], ...result?.[1] }
  }, [result])

  return {
    result: data,
    loading,
  }
}

export const useEstimateDecreaseL = (long0?: boolean, _rgPool?: string, _sizeDelta?: string) => {
  const tradeReader = useTradeReader()

  const { account } = useActiveChainId()

  const parmes = useMemo(() => {
    if (!_rgPool || !_sizeDelta || !account || long0 === undefined) return
    const key = gethash(account, _rgPool, long0)

    return [_rgPool, _sizeDelta, key]
  }, [_rgPool, _sizeDelta, account, long0])

  const { result, loading, error } = useSingleCallResult(tradeReader, 'estimateDecreaseL', parmes ?? [undefined])
  useEffect(() => {
    // console.log('estimateDecrease', result, loading, error)
  }, [error, loading, parmes, result])
  const data = useMemo(() => {
    if (!result) return
    return { ...result?.[0], ...result?.[1] }
  }, [result])

  return {
    result: data,
    loading,
  }
}

// eslint-disable-next-line import/no-unused-modules
export const useDetailsPool = () => {
  const state = useTradeSWAPState()

  const currency0 = state?.poolInfo?.token0
  const currency1 = state?.poolInfo?.token1
  const tradeReader = useTradeReader()
  const v3PoolContract = useV3PoolContract(state?.poolInfo?.id)

  const twapPrice = useTwapPrice(state?.poolInfo?.id)
  const { result: slot0 } = useSingleCallResult(v3PoolContract, 'slot0')

  const currentSqrtRatioX96: undefined | BigNumber = useMemo(() => slot0?.[0], [slot0])

  const { result: fee, loading: feeLoading } = useSingleCallResult(tradeReader, 'fee', [state?.poolInfo?.tradePool])

  const traderFee: feeProps | undefined = useMemo(() => fee?.[0], [fee])
  const tokenRatio = useMemo(() => {
    if (!state || !currency0 || !currency1 || !twapPrice) return
    const tokenA = CurrencyAmount.fromRawAmount(currency0, toWei(1, currency0.decimals).toFixed(0))
    const tokenB = CurrencyAmount.fromRawAmount(
      currency1,
      toWei(
        fromSqrt96Wei(twapPrice?.toString(), currency0.decimals, currency1.decimals).toFixed(),
        currency1.decimals
      ).toFixed(0)
    )
    const price = new Price({ baseAmount: tokenA, quoteAmount: tokenB })
    return price
  }, [currency0, currency1, state, twapPrice])

  const [userSlippageTolerance] = useUserSlippageTolerance()
  const autoSlippage = useAutoSlippageTolerance(undefined)
  const slippageTolerance = useMemo(() => {
    return userSlippageTolerance === SlippageTolerance.Auto ? autoSlippage.toFixed(2) : userSlippageTolerance.toFixed(2)
  }, [autoSlippage, userSlippageTolerance])

  const symbol = useMemo(() => {
    if (!currency0 || !currency1) return
    return state.isToken0 ? `${currency0?.symbol}/${currency1?.symbol}` : `${currency1?.symbol}/${currency0?.symbol}`
  }, [currency0, currency1, state.isToken0])
  return {
    loading: feeLoading,
    currency0,
    currency1,
    currentSqrtRatioX96,
    tradePool: state?.poolInfo?.tradePool,
    tokenRatio,
    slippageTolerance,
    traderFee,
    symbol,
    twapPrice,
  }
}

// eslint-disable-next-line import/no-unused-modules
export const usePositonDetail = (Positon?: TradePositionProps) => {
  const token0 = useCurrency(Positon?.token0)
  const token1 = useCurrency(Positon?.token1)

  const { isToken0, poolInfo } = useTradeSWAPState()

  const twapPrice = useTwapPrice(Positon?.spotPool)

  // const v3PoolContract = useV3PoolContract(Positon?.spotPool)

  // const { result: slot0 } = useSingleCallResult(v3PoolContract, 'slot0')

  // const currentSqrtRatioX96: undefined | BigNumber = useMemo(() => slot0?.[0], [slot0])

  const tradeReader = useTradeReader()

  const { result: fee } = useSingleCallResult(tradeReader, 'fee', [Positon?.pool])

  const traderFee: feeProps | undefined = useMemo(() => fee?.[0], [fee])

  const tokenRatio = useMemo(() => {
    if (!token0 || !token1 || !twapPrice) return
    const tokenA = CurrencyAmount.fromRawAmount(token0.wrapped, toWei(1, token0.decimals).toFixed(0))
    const tokenB = CurrencyAmount.fromRawAmount(
      token1,
      toWei(fromSqrt96Wei(twapPrice?.toString(), token0.decimals, token1.decimals).toFixed(), token1.decimals).toFixed(
        0
      )
    )

    const price = new Price({ baseAmount: tokenA, quoteAmount: tokenB })

    return price
  }, [token0, token1, twapPrice])
  const [userSlippageTolerance] = useUserSlippageTolerance()
  const autoSlippage = useAutoSlippageTolerance(undefined)
  const slippageTolerance = useMemo(() => {
    return userSlippageTolerance === SlippageTolerance.Auto ? autoSlippage.toFixed(2) : userSlippageTolerance.toFixed(2)
  }, [autoSlippage, userSlippageTolerance])

  const positonsymbol = useMemo(
    () => (isToken0 ? `${token0?.symbol}/${token1?.symbol}` : `${token1?.symbol}/${token0?.symbol}`),
    [isToken0, token0?.symbol, token1?.symbol]
  )

  const symbol = useMemo(
    () => (isToken0 ? `${token0?.symbol}-${token1?.symbol}` : `${token1?.symbol}-${token0?.symbol}`),
    [isToken0, token0?.symbol, token1?.symbol]
  )

  return {
    currentSqrtRatioX96: twapPrice,
    token0,
    token1,
    tradePool: Positon?.pool,
    tokenRatio,
    traderFee,
    slippageTolerance,
    positonsymbol,
    symbol,
    isToken0,
  }
}

export const useTwapPrice = (v3pool?: string) => {
  const roxUtils = useRoxUtils()

  const { result } = useSingleCallResult(roxUtils, 'getSqrtTwapX96', [v3pool])

  return useMemo(() => {
    if (!result) return
    return result.sqrtPriceX96 as BigNumber
  }, [result])
}

const gethash = (account: string, roxpool: string, long0: boolean) => {
  const hash2 = keccak256(solidityPack([`address`, `address`, `bool`], [account, roxpool, long0]))
  return hash2
}
